import Rest from '@/services/Rest';

/**
 * @typedef {KitService}
 */
export default class KitService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/kit'
}
