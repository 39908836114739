<template>
<div style="margin-top: 100px">
    <BasePageBreadCrumb :pages="pages" :title="`${kits.length} kits`" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                <button type="submit" class="btn btn-info" @click="fetchKit">Buscar</button>
                            </div>
                           
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                            <div class="form-group d-flex">
                                <b-link :to="{name: 'KitNew'}" class=" ml-auto">
                                    <button type="submit" class="btn btn-dark"><i class="far fa-file-alt mr-2"></i>Novo kit</button>
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-7 col-md-10 col-lg-10 p-0 order-3 text-center align-self-center">
                                            <div class="col-12">
                                                <div class="row">                                                
                                                    <div class="col-12 col-md-3 col-lg-2 align-self-center text-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Nome do kit</p>
                                                    </div>
                                                    <div class="col-12 col-md-3 col-lg-7 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Valor</p>
                                                    </div>
                                                    <div class="col-12 col-md-2 col-lg-2 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Tipologias</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-list" v-for="item in kits" :key="item.id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <!-- <img src="../../assets/images/hub_/pedra-04.png" alt="user" class="rounded-circle card-body-list-img" /> -->
                                        </div>
                                        <div class="col-8 col-md-7 col-lg-10 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-5 align-self-center pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title">{{ item.name }}</h6>
                                                        <h6 class="text-truncate empreendi">{{ item.products.length }} produtos</h6>
                                                    </div>
                                                    <div class="col-12 col-md-2 align-self-center text-muted pad-content-list">
                                                        <h6 class="text-truncate empreendi">R$ {{ item.price }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-3 align-self-center text-muted pad-content-list">
                                                        <h6 class="text-truncate empreendi"><div v-for="(tipology, index) in item.tipologies" :key="tipology.id"><b>{{ tipology.tipology.code }}</b> - {{ tipology.tipology.name }} <span v-if="index != item.tipologies.length - 1">  </span> </div></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <b-link :to="{name: 'KitEdit', params: {id: item.id}}" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" >+</b-link>
                                             <a class="btn btn-danger text-white rounded-circle btn-circle font-16 ml-2" href="javascript:void(0);" @click="removeKit(item.id)"><i class="far fa-trash-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 text-center p-4" v-if="kits.length == 0">
                    Nenhum kit encontrado.
                </div>
            </div>

            <div class="col-12">
                <BasePagination
                    v-if="kits.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage" />
            </div>
            </div>
        </div>
</div>
</template>
<script>
import KitService from "@/services/resources/KitService";
const service = KitService.build();

export default {
    data(){
        return{
            kits: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            search: null,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'clientes',
                    to: 'Client'
                }
            ],
            menu:[
                {
                    name: 'Novo cliente',
                    to: 'ClientNew'
                },
                {
                    name: 'Clientes',
                    to: 'Client'
                }
            ]
        }
    },
    methods:{
         toPage(page) {
            this.pagination.currentPage = page;
            this.fetchKit();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchKit();
        },
        fetchKit(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data['search'] = this.search;
            }

             service
                .search(data)
                .then(resp => {
                    this.kits = resp.data;
                    this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
                })
                .catch(err => {
                console.log(err)
                })
        },
        removeKit(id){

          this.$bvModal.msgBoxConfirm('Tem certeza que deseja excluir esse kit?')
          .then(value => {

                if(value) {

                    let data = {
                        id: id
                    };

                    service
                    .destroy(data)
                    .then(resp => {
                        this.$bvToast.toast('Seu kit foi excluído com sucesso!', {
                            title: 'Excluir kit',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                        this.fetchKit();
                    })
                    .catch(err => {
                        this.$bvToast.toast('Seu kit não pode ser excluído.', {
                            title: 'Excluir kit',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }
                
          })
          .catch(err => {
            // An error occurred
          })
            

        },
    },
    mounted(){
        this.fetchKit();
    }
}
</script>